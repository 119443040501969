import { render, staticRenderFns } from "./InitSvg.vue?vue&type=template&id=1f341336&scoped=true&"
import script from "./InitSvg.vue?vue&type=script&lang=js&"
export * from "./InitSvg.vue?vue&type=script&lang=js&"
import style0 from "./InitSvg.vue?vue&type=style&index=0&id=1f341336&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f341336",
  null
  
)

export default component.exports