<template>
  <div
    class="initSvgConfiguration"
  >
    <svg
      ref="svgeditdemo"
      :height="imgHeight"
      :width="imgWidth"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle
          id="point-handle"
          r="3"
          x="0"
          y="0"
          stroke-width="4"
          fill="#fd7580"
          fill-opacity="0.4"
          stroke="#fd7580"
        />
        <circle
          id="point-handle-triggerLine"
          r="3"
          x="0"
          y="0"
          stroke-width="4"
          fill="#fff"
          fill-opacity="0.4"
          stroke="#0ff"
        />
        <circle
          id="point-handle-focusPoint"
          r="3"
          x="0"
          y="0"
          stroke-width="4"
          fill="#fcf803"
          fill-opacity="0.4"
          stroke="#fcf803"
        />
      </defs>
      <polygon
        ref="editstarTriggerPolygon"
        stroke="#ee3a6a"
        stroke-width="2"
        stroke-linejoin="round"
        fill="none"
        :points="triggerArea"
      />
      <polygon
        ref="editstarTriggerLine"
        stroke="#29e"
        stroke-width="2"
        stroke-linejoin="round"
        fill="none"
        :points="triggerLine"
      />
      <polygon
        ref="editstarFocusPoint"
        stroke="#fcf803"
        stroke-width="2"
        stroke-linejoin="round"
        fill="none"
        :points="focusPoint"
      />
    </svg>
  </div>
</template>

<script>
import interact from "interactjs";
export default {
  name: "InitSvg",
  props: {
    imgHeight: {
      type: Number,
      required: true
    },
    imgWidth: {
      type: Number,
      required: true
    },
    triggerArea: {
      type: Array,
      required: true
    },
    triggerLine: {
      type: Array,
      required: true
    },
    focusPoint: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      rootMatrix: null,
      transformedTrigger: [],
      transformedTriggerLine: [],
      transformedFocusPoint: []
    };
  },
  mounted () {
    let sns = "http://www.w3.org/2000/svg";
    let xns = "http://www.w3.org/1999/xlink";
    let root = this.$refs.svgeditdemo;
    let starTriggerLine = this.$refs.editstarTriggerLine;
    let starTriggerPolygon= this.$refs.editstarTriggerPolygon;
    let starFocusPoint= this.$refs.editstarFocusPoint;

    for (let i = 0, len = starTriggerLine.points.numberOfItems; i < len; i++) {
      let handle = document.createElementNS(sns, "use");
      let point = starTriggerLine.points.getItem(i);
      let newPoint = root.createSVGPoint();

      handle.setAttributeNS(xns, "href", "#point-handle-triggerLine");
      handle.setAttribute("class", "point-handle-triggerLine");
      handle.x.baseVal.value = newPoint.x = point.x;
      handle.y.baseVal.value = newPoint.y = point.y;
      handle.setAttribute("data-index", i);
      let relativeX = Math.round((newPoint.x * 100) / this.imgWidth);
      let relativeY = Math.round((newPoint.y * 100) / this.imgHeight);
      this.transformedTriggerLine.push({ x: relativeX, y: relativeY });
      root.appendChild(handle);
    }

    for (let j = 0, len = starTriggerPolygon.points.numberOfItems; j < len; j++) {
      let handle = document.createElementNS(sns, "use");
      let point = starTriggerPolygon.points.getItem(j);
      let newPoint = root.createSVGPoint();

      handle.setAttributeNS(xns, "href", "#point-handle");
      handle.setAttribute("class", "point-handle");
      handle.x.baseVal.value = newPoint.x = point.x;
      handle.y.baseVal.value = newPoint.y = point.y;
      handle.setAttribute("data-index", j);

      let relativeX = Math.round((newPoint.x * 100) / this.imgWidth);
      let relativeY = Math.round((newPoint.y * 100) / this.imgHeight);
      this.transformedTrigger.push({ x: relativeX, y: relativeY });
      root.appendChild(handle);
    }

    for (let j = 0, len = starFocusPoint.points.numberOfItems; j < len; j++) {
      let handle = document.createElementNS(sns, "use");
      let point = starFocusPoint.points.getItem(j);
      let newPoint = root.createSVGPoint();

      handle.setAttributeNS(xns, "href", "#point-handle-focusPoint");
      handle.setAttribute("class", "point-handle-focusPoint");
      handle.x.baseVal.value = newPoint.x = point.x;
      handle.y.baseVal.value = newPoint.y = point.y;
      handle.setAttribute("data-index", j);

      let relativeX = Math.round((newPoint.x * 100) / this.imgWidth);
      let relativeY = Math.round((newPoint.y * 100) / this.imgHeight);
      this.transformedFocusPoint.push({ x: relativeX, y: relativeY });
      root.appendChild(handle);
    }

    this.rootMatrix = root.getScreenCTM();

    interact(".point-handle", { context: document })
      .draggable({
        restrict: {
          restriction: "parent",
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        },
        onmove: event => {
          let i = event.target.getAttribute("data-index") | 0;
          let point = starTriggerPolygon.points.getItem(i);

          point.x += event.dx / this.rootMatrix.a;
          point.y += event.dy / this.rootMatrix.d;
          event.target.x.baseVal.value = point.x;
          event.target.y.baseVal.value = point.y;

          let relativeX = Math.round((point.x * 100) / this.imgWidth);
          let relativeY = Math.round((point.y * 100) / this.imgHeight);
          this.transformedTrigger[i].x = relativeX;
          this.transformedTrigger[i].y = relativeY;
        }
      })
      .styleCursor(false);

     interact(".point-handle-triggerLine", { context: document })
      .draggable({
        restrict: {
          restriction: "parent",
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        },
        onmove: event => {
          let i = event.target.getAttribute("data-index") | 0;
          let point = starTriggerLine.points.getItem(i);

          point.x += event.dx / this.rootMatrix.a;
          point.y += event.dy / this.rootMatrix.d;
          event.target.x.baseVal.value = point.x;
          event.target.y.baseVal.value = point.y;

          let relativeX = Math.round((point.x * 100) / this.imgWidth);
          let relativeY = Math.round((point.y * 100) / this.imgHeight);
          this.transformedTriggerLine[i].x = relativeX;
          this.transformedTriggerLine[i].y = relativeY;
        }
      })
      .styleCursor(false);

     interact(".point-handle-focusPoint", { context: document })
      .draggable({
        restrict: {
          restriction: "parent",
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        },
        onmove: event => {
          let i = event.target.getAttribute("data-index") | 0;
          let point = starFocusPoint.points.getItem(i);

          point.x += event.dx / this.rootMatrix.a;
          point.y += event.dy / this.rootMatrix.d;
          event.target.x.baseVal.value = point.x;
          event.target.y.baseVal.value = point.y;

          let relativeX = Math.round((point.x * 100) / this.imgWidth);
          let relativeY = Math.round((point.y * 100) / this.imgHeight);
          this.transformedFocusPoint[i].x = relativeX;
          this.transformedFocusPoint[i].y = relativeY;
        }
      })
      .styleCursor(false);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dragging {
  background-color: #f0f0f0;
}
</style>
